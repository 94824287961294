export const document = {
    DOCUMENT_TYPE: {
        "RC": { id: 1, name: "RC" },
        "EMIRATES_ID": { id: 2, name: "EMIRATES ID" },
        "PASSPORT": { id: 3, name: "PASSPORT" },
        "DRIVING_LICENSE": { id: 4, name: "DRIVING LICENSE" },
        "CONSIGNMENT_NOTE": { id: 5, name: "CONSIGNMENT NOTE" },
        "DELIVERY_NOTE": { id: 6, name: "DELIVERY NOTE" },
        "TRADE_LICENSE" : { id : 7 , name : 'TRADE LICENSE'},
        "VAT_CERTIFICATE" : {id : 8 , name : 'VAT CERTIFICATE'},
        "VISA" : {id : 9 , name : 'VISA'},
        "MULKIYA_FB" : {id : 10 , name : 'MULKIYA F/B'},
    },
    DOCUMENT_STATUS: {
        "Approved": { id: 1, name: "Approved" },
        "Pending": { id: 2, name: "Pending" },
        "Rejected": { id: 3, name: "Rejected" }
    }
}